import React, { Suspense, lazy } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import routes from "../routes";

const Home  = lazy(()=> import( "./Pages/Home"));
const About  = lazy(()=> import( "./Pages/About"));
const Error  = lazy(()=> import( "./Pages/Error"));
const ContactUs  = lazy(()=> import( "./Pages/ContactUs"));
const ProjectGrid  = lazy(()=> import( "./Pages/ProjectGrid"));
const ProjectConstruction = lazy(()=> import("./Pages/ProjectConstruction"));
const ProjectRenovation= lazy(()=> import("./Pages/ProjectRenovation"));
const ProjectRoomsAndHalls = lazy(()=> import("./Pages/ProjectRoomsAndHalls"));
const ProjectIbrahimRugova = lazy(()=> import("./Pages/ProjectIbrahimRugova"));
const ProjectAleksanderMoisiu = lazy(()=> import("./Pages/ProjectAleksanderMoisiu"));

class Components extends React.Component {
  render() {
    return (
      <BrowserRouter basename="/">
        <div className="page-wraper">
          <Suspense fallback={<div></div>}>
            <Switch>
              <Route path={routes.Home} exact component={Home} />
              <Route path={routes.About} exact component={About} />
              <Route path={routes.Error} exact component={Error} />
              <Route path={routes.ContactUs} exact component={ContactUs} />
              <Route path={routes.ProjectGrid} exact component={ProjectGrid} />
              <Route
                path={routes.ProjectConstruction}
                exact
                component={ProjectConstruction}
              />
              <Route
                path={routes.ProjectRenovation}
                exact
                component={ProjectRenovation}
              />
              <Route
                path={routes.ProjectRoomsAndHalls}
                exact
                component={ProjectRoomsAndHalls}
              />
              <Route
                path={routes.ProjectIbrahimRugova}
                exact
                component={ProjectIbrahimRugova}
              />
              <Route
                path={routes.ProjectAleksanderMoisiu}
                exact
                component={ProjectAleksanderMoisiu}
              />
              <Route component={Error} />
            </Switch>
          </Suspense>
        </div>
      </BrowserRouter>
    );
  }
}

export default Components;
