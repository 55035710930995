const routes = /** @type {const} */ ({
  Home: "/",
  About: "/about",
  Services: "/services",
  Error: '/error',
  ContactUs: "/contactus",
  ProjectGrid: "/project-grid",
  ProjectConstruction: "/project-construction",
  ProjectRenovation: "/project-renovation",
  ProjectRoomsAndHalls: "/project-rooms-halls",
  ProjectIbrahimRugova: "/project-ibrahim-rugova",
  ProjectAleksanderMoisiu: "/project-aleksander-moisiu",
}); 
module.exports = routes;
